// prefer default export if available
const preferDefault = m => m && m.default || m

exports.components = {
  "component---src-pages-404-js": () => import("./../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-contact-js": () => import("./../../src/pages/contact.js" /* webpackChunkName: "component---src-pages-contact-js" */),
  "component---src-pages-contact-success-js": () => import("./../../src/pages/contact-success.js" /* webpackChunkName: "component---src-pages-contact-success-js" */),
  "component---src-pages-index-js": () => import("./../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-services-js": () => import("./../../src/pages/services.js" /* webpackChunkName: "component---src-pages-services-js" */),
  "component---src-pages-testimonials-js": () => import("./../../src/pages/testimonials.js" /* webpackChunkName: "component---src-pages-testimonials-js" */),
  "component---src-templates-blog-blog-archive-template-jsx": () => import("./../../src/templates/blog/blog-archive.template.jsx" /* webpackChunkName: "component---src-templates-blog-blog-archive-template-jsx" */),
  "component---src-templates-blog-blog-template-jsx": () => import("./../../src/templates/blog/blog.template.jsx" /* webpackChunkName: "component---src-templates-blog-blog-template-jsx" */),
  "component---src-templates-blog-category-guide-template-jsx": () => import("./../../src/templates/blog/category-guide.template.jsx" /* webpackChunkName: "component---src-templates-blog-category-guide-template-jsx" */),
  "component---src-templates-blog-category-product-template-jsx": () => import("./../../src/templates/blog/category-product.template.jsx" /* webpackChunkName: "component---src-templates-blog-category-product-template-jsx" */),
  "component---src-templates-blog-category-sustainability-template-jsx": () => import("./../../src/templates/blog/category-sustainability.template.jsx" /* webpackChunkName: "component---src-templates-blog-category-sustainability-template-jsx" */),
  "component---src-templates-blog-category-technology-template-jsx": () => import("./../../src/templates/blog/category-technology.template.jsx" /* webpackChunkName: "component---src-templates-blog-category-technology-template-jsx" */)
}

